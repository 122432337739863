<template>
  <v-card flat class="">
    <v-data-table
      dense
      :headers="table.headers"
      :items="table.items"
      :options.sync="tableOptions"
      :server-items-length="table.totalItems"
      :loading="table.loading"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Pengguna</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="table.search"
            @change="getDataFromApi()"
            :prepend-inner-icon="icons.mdiMagnify"
            label="Cari dengan email/nama/role/no hp"
            single-line
            hide-details
          ></v-text-field>

          <!-- dialog user -->
          <v-dialog v-model="form.dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="addItem()">
                Tambah
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5" v-if="form.editedIndex === -1">Tambah Data</span>
                <span class="text-h5" v-else>Lihat Data</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="form.editedIndex === -1"
                      v-model="form.editedItem.email"
                      outlined
                      dense
                      hide-details=""
                      label="email :"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      readonly
                      v-model="form.editedItem.email"
                      outlined
                      dense
                      hide-details=""
                      label="email :"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="form.editedItem.role"
                      :items="['ADMIN', 'PESERTA', 'PEMATERI']"
                      outlined
                      dense
                      hide-details=""
                      label="role :"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.editedItem.nama"
                      outlined
                      dense
                      hide-details=""
                      label="Nama :"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.editedItem.nip"
                      outlined
                      dense
                      hide-details=""
                      label="NIP :"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.editedItem.hp"
                      outlined
                      dense
                      hide-details=""
                      label="No HP :"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="form.editedIndex === -1"
                      v-model="form.editedItem.password"
                      outlined
                      dense
                      hide-details=""
                      label="Password :"
                      type="password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    Verifikasi User:
                    <v-chip color="warning" v-if="form.editedItem.email_verified_at == null">Not Verified</v-chip>
                    <v-chip color="success" v-else>Verified at {{ form.editedItem.email_verified_at }}</v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="save()"> Simpan </v-btn>
                <!-- <v-btn v-if="form.editedItem.email_verified_at == null" color="primary" @click="verifikasiUser()">
                  Verifikasi User
                </v-btn> -->
                <v-btn color="warning" v-if="form.editedIndex !== -1" @click="showDialogResetPassword()">
                  Reset Password
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- dialog reset password -->
          <v-dialog v-model="dialogResetPassword" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Form Reset Password</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.editedItem.password_baru"
                      outlined
                      dense
                      hide-details=""
                      label="Password Baru :"
                      type="password"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="saveResetPassword()">Simpan</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn title="Lihat" x-small color="primary" @click="editItem(item)">
          <v-icon x-small>
            {{ icons.mdiEye }}
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn small color="primary" @click="getDataFromApi"> Reload </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {},
  data: () => ({
    icons: {
      mdiEye,
      mdiPencil,
      mdiMagnify,
    },
    tableOptions: {},
    table: {
      loading: true,
      headers: [
        { text: 'No', align: 'start', sortable: false, value: 'no' },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Nama', value: 'nama', sortable: false },
        { text: 'NIP', value: 'nip', sortable: false },
        { text: 'No HP', value: 'hp', sortable: false },
        { text: 'Role', value: 'role', sortable: false },
        { text: 'Aksi', value: 'actions', sortable: false },
      ],
      items: [],
      totalItems: 0,
      search: '',
    },
    dialogResetPassword: false,
    form: {
      dialog: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        email: '',
        role: '',
        nama: '',
        nip: '',
        hp: '',
        password: '',
        password_baru: '',
      },
      defaultItem: {
        id: '',
        email: '',
        role: '',
        nama: '',
        nip: '',
        hp: '',
        password: '',
        password_baru: '',
      },
    },
  }),
  watch: {
    tableOptions: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  computed: {
    userSelected() {
      return this.$store.getters.userSelected
    },
  },
  methods: {
    verifikasiUser() {
      let conf = confirm('Apakah yakin data sudah valid dan lengkap?')
      if (conf) {
        const fmData = new FormData()
        fmData.append('user_id', this.userSelected.id)
        axiosPostAuth('api/User/verifikasiUser', fmData)
          .then(response => {
            this.dialog = false
            this.getDataFromApi()
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    save() {
      if (this.form.editedIndex > -1) {
        const fmData = new FormData()
        fmData.append('id', this.form.editedItem.id)
        fmData.append('aksi', 'update')
        fmData.append('email', this.form.editedItem.email)
        fmData.append('role', this.form.editedItem.role)
        fmData.append('nama', this.form.editedItem.nama)
        fmData.append('nip', this.form.editedItem.nip)
        fmData.append('hp', this.form.editedItem.hp)
        fmData.append('password', this.form.editedItem.password)
        axiosPostAuth('api/User/addUser', fmData)
          .then(response => {
            if (response.code === 200) {
              alert(response.message)
              this.form.dialog = false
              this.getDataFromApi()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        const fmData = new FormData()
        fmData.append('aksi', 'add')
        fmData.append('email', this.form.editedItem.email)
        fmData.append('role', this.form.editedItem.role)
        fmData.append('nama', this.form.editedItem.nama)
        fmData.append('nip', this.form.editedItem.nip)
        fmData.append('hp', this.form.editedItem.hp)
        fmData.append('password', this.form.editedItem.password)
        axiosPostAuth('api/User/addUser', fmData)
          .then(response => {
            if (response.code === 200) {
              alert(response.message)
              this.form.dialog = false
              this.getDataFromApi()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.tableOptions
      const fmData = new FormData()
      fmData.append('page', page)
      fmData.append('itemsPerPage', itemsPerPage)
      fmData.append('search', this.table.search)
      axiosPostAuth('api/User/getUser_datatable', fmData)
        .then(response => {
          this.table.items = response.data
          this.table.totalItems = response.total
          this.table.loading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    addItem() {
      this.form.editedItem = Object.assign({}, this.form.defaultItem)
      this.form.editedIndex = -1
    },
    editItem(item) {
      this.form.editedIndex = this.table.items.indexOf(item)
      this.form.editedItem = Object.assign({}, item)
      this.form.dialog = true
    },
    showDialogResetPassword() {
      let conf = confirm('Apakah yakin akan mereset password?')
      if (conf) {
        this.form.editedItem.password_baru = ''
        this.dialogResetPassword = true
      }
    },
    saveResetPassword() {
      const fmData = new FormData()
      fmData.append('user_id', this.form.editedItem.id)
      fmData.append('password_baru', this.form.editedItem.password_baru)
      axiosPostAuth('api/User/resetPassword', fmData)
        .then(response => {
          if (response.code == 200) {
            alert(response.message)
            this.form.editedItem.password_baru = ''
            this.dialogResetPassword = false
          } else {
            alert(response.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
